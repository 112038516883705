import { useMemo } from 'react'

import { AsyncSelect } from '../../../components/Forms/AsyncSelect'
import { Props as SelectProps } from '../../../components/Forms/Select'
import { PAGE_SIZE_DROPDOWN } from '../../../services/client'
import { getQuotes, IFilterQuotes, IQuote } from '../../../services/quotes'
import { formatDateTime } from '../../../utils/dateTime'
import { OptionValue } from '../../../utils/form'

interface Props extends Omit<SelectProps, 'options' | 'onSelect'> {
  selected?: OptionValue | null
  onSelect?: (option: OptionValue | null, valueRaw?: IQuote | null) => void
  className?: string
  filterQuotes?: IFilterQuotes | null
  excludesValues?: number[]
}
export const QuoteSelect = ({
  selected = null,
  className = '',
  onSelect,
  filterQuotes = null,
  excludesValues = [],
  ...props
}: Props) => {
  const handleGetQuotes = async ({ search = '', page = 1 } = {}) => {
    try {
      const { data } = await getQuotes({
        search,
        currentPage: page,
        pageSize: PAGE_SIZE_DROPDOWN,
        filterArchived: 'exclude',
        ...filterQuotes,
      })
      return { data: data.data, totalPage: data.meta.last_page }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSelect = (item: any, itemFull: IQuote) => {
    const value = !!item
      ? {
          value: item.value,
          label: itemFull.regarding
            ? itemFull.regarding
            : itemFull.quote_number + '',
        }
      : null
    onSelect?.(value, itemFull)
  }

  const triggerValue = useMemo(() => {
    return filterQuotes?.filterAccount?.value
  }, [filterQuotes?.filterAccount?.value])

  return (
    <AsyncSelect
      placeholder='All Quotes'
      {...props}
      labelInValue
      allowClear
      onChange={handleSelect}
      fetcher={handleGetQuotes}
      dropdownClassName='w-full'
      value={selected}
      className={className}
      triggerFilter={triggerValue}
      fetchOnFirst={false}
    >
      {(data: IQuote[]) => {
        return data.map(item =>
          !excludesValues.includes(item.id) ? (
            <AsyncSelect.Option key={item.id}>
              <div className='flex gap-1 flex-col py-0.5 w-[calc(100%_-_1rem)]'>
                <div className='truncate overflow-hidden'>{item.regarding}</div>
                <div className='flex gap-2 text-black-400 items-center'>
                  <div>{item.quote_number}</div>
                  <div className='h-4 w-px bg-black-400' />
                  <div>Created at {formatDateTime(item.created_at)}</div>
                </div>
              </div>
            </AsyncSelect.Option>
          ) : null,
        )
      }}
    </AsyncSelect>
  )
}
