import { Navigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { PATHNAME } from '../configs/routes'
import useStore, { Store } from '../store'
import {
  PERMISSIONS_CREATE,
  PERMISSIONS_DELETE,
  PERMISSIONS_UPDATE,
  PERMISSIONS_VIEW,
  PERMISSIONS_VIEW_OWN,
} from '../utils/permission'

interface Props {
  name?: string
  type?: string
  permission?: string
}
const mapStore = (store: Store) => ({
  listPermissions: store.auth.currentUser?.can,
})
export const usePermission = ({ name, type, permission }: Props) => {
  const { listPermissions = [] } = useStore(mapStore, shallow)

  const redirectNoPermision = () => (
    <Navigate to={`/${PATHNAME['no-permission']}`} />
  )

  const canView = name
    ? Boolean(listPermissions?.includes(`${PERMISSIONS_VIEW} ${name}`)) ||
      Boolean(listPermissions?.includes(`${PERMISSIONS_VIEW_OWN} ${name}`))
    : false

  const canUpdate = name
    ? Boolean(listPermissions?.includes(`${PERMISSIONS_UPDATE} ${name}`))
    : false
  const canDelete = name
    ? Boolean(listPermissions?.includes(`${PERMISSIONS_DELETE} ${name}`))
    : false

  const canCreate = name
    ? Boolean(listPermissions?.includes(`${PERMISSIONS_CREATE} ${name}`))
    : false

  const hasPermission = type
    ? Boolean(listPermissions?.includes(`${type} ${name}`))
    : false

  const hasPermissionDetail = permission
    ? Boolean(listPermissions?.includes(permission))
    : false

  return {
    canView,
    canCreate,
    canDelete,
    canUpdate,
    hasPermission,
    hasPermissionDetail,
    redirectNoPermision,
  }
}
