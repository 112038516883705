import { Link } from 'react-router-dom'

import Logo from '../../assets/images/logo_system.png'

export const FooterPublicPage = () => {
  return (
    <div className='flex justify-between items-center text-body flex-col lg:flex-row gap-2 px-4 pt-4'>
      <div className='text-black-400'>
        © Copyright {new Date().getFullYear()} Readyflow
      </div>
      <div className='flex gap-2 items-center text-black-400'>
        <span>Powered by</span>
        <img src={Logo} width={16} height={20} alt='logo' />
        <span>Readyflow</span>
      </div>
      <div className='inline-flex gap-6'>
        <Link to='/privacy' className='text-primary-900 font-medium'>
          Privacy Policy
        </Link>
        <Link
          to='/tos'
          className='text-primary-900 font-medium flex items-center gap-x-1.5'
        >
          <img src={Logo} width={16} height={20} alt='logo' />
          <span>Readyflow</span>
        </Link>
      </div>
    </div>
  )
}
