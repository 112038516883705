import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import useStore from '../../store'
import { PERMISSIONS, PERMISSIONS_VIEW } from '../../utils/permission'

export const RoleBasedRedirect = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const permissions = useStore(state => state.auth.currentUser?.can)
  const dashboard = `${PERMISSIONS_VIEW} ${PERMISSIONS.APP_DASHBOARD}`
  const customerPortal = `${PERMISSIONS_VIEW} ${PERMISSIONS.CUSTOMER_PORTAL}`

  useEffect(() => {
    if (!permissions) {
      return
    }
    if (!!permissions.includes(dashboard)) {
      // it's admin, no further actions
      return
    }
    if (permissions.includes(customerPortal)) {
      if (!location.pathname.startsWith('/customer')) {
        navigate('/customer')
      }
    } else {
      navigate('/no-permission')
    }
  }, [permissions, location])

  return <Outlet />
}
