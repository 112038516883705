import { SVGProps } from 'react'

export const Slack = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_11411_68585)'>
        <path
          d='M3.40723 10.0894C3.40723 11.0057 2.65998 11.7541 1.7436 11.7541C0.827227 11.7541 0.0791016 11.0057 0.0791016 10.0894C0.0791016 9.17318 0.827477 8.4248 1.74373 8.4248H3.40735L3.40723 10.0894ZM4.24585 10.0894C4.24585 9.17318 4.99423 8.4248 5.91048 8.4248C6.82673 8.4248 7.5751 9.17305 7.5751 10.0894V14.2563C7.5751 15.1726 6.82685 15.9209 5.91048 15.9209C4.99423 15.9209 4.24585 15.1726 4.24585 14.2563V10.0894Z'
          fill='#DE1C59'
        />
        <path
          d='M5.91048 3.40723C4.99423 3.40723 4.24585 2.65998 4.24585 1.7436C4.24585 0.827227 4.99423 0.0791016 5.91048 0.0791016C6.82673 0.0791016 7.5751 0.827477 7.5751 1.74373V3.40735L5.91048 3.40723ZM5.91048 4.24585C6.82673 4.24585 7.5751 4.99423 7.5751 5.91048C7.5751 6.82673 6.82685 7.5751 5.91048 7.5751H1.7436C0.827352 7.5751 0.0791016 6.82685 0.0791016 5.91048C0.0791016 4.99423 0.827477 4.24585 1.74373 4.24585H5.91048Z'
          fill='#35C5F0'
        />
        <path
          d='M12.5927 5.91048C12.5927 4.99423 13.3399 4.24585 14.2563 4.24585C15.1727 4.24585 15.9209 4.99423 15.9209 5.91048C15.9209 6.82673 15.1726 7.5751 14.2563 7.5751H12.5927V5.91048ZM11.7541 5.91048C11.7541 6.82673 11.0057 7.5751 10.0894 7.5751C9.17318 7.5751 8.4248 6.82685 8.4248 5.91048V1.7436C8.4248 0.827352 9.17305 0.0791016 10.0894 0.0791016C11.0057 0.0791016 11.7541 0.827477 11.7541 1.74373V5.91048Z'
          fill='#2EB57D'
        />
        <path
          d='M10.0894 12.5927C11.0057 12.5927 11.7541 13.3399 11.7541 14.2563C11.7541 15.1727 11.0057 15.9209 10.0894 15.9209C9.17318 15.9209 8.4248 15.1726 8.4248 14.2563V12.5927H10.0894ZM10.0894 11.7541C9.17318 11.7541 8.4248 11.0057 8.4248 10.0894C8.4248 9.17318 9.17305 8.4248 10.0894 8.4248H14.2563C15.1726 8.4248 15.9209 9.17305 15.9209 10.0894C15.9209 11.0057 15.1726 11.7541 14.2563 11.7541H10.0894Z'
          fill='#EBB02E'
        />
      </g>
      <defs>
        <clipPath id='clip0_11411_68585'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
